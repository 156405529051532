
import React, { useContext, useState, useEffect } from "react";
import { Select } from "../../components/Core";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import { FileUpload, getFileName } from "../../utils/fileUpload";
import ConfirmMessages from "../../utils/confirmMessages";
const ModalStyled = styled(Modal)`
   &.modal {
    z-index: 10050; 
  }
`;

const ModalConfirm = (props) => {
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const gContext = useContext(GlobalContext);
    const [disabled, SetDisabled] = useState(true);
    const [error, setError] = useState("");
    const [uploadtooltip, setUploadTooltip] = useState(false);
    const [values, setValues] = React.useState({
        resetFeatureName: '', featureName: '', feature: null, featureType: '', featureTypeValue: '', error: { featureTypeValue: "", feature:""}
    });

    const onFileChange = (event) => {
        if (event.target.files.length > 0) {
            let error = { ...values.error };
            error.feature = "";
            setValues({ ...values,error, ["feature"]: event.target.files[0] });
        }
    }

    const handleClose = () => {
        SetDisabled(true);
        setError("");
        gContext.setconfirmModal({ text: '', ["confirmed"]: false });
        gContext.toggleConfirmModal();
    };
    
    function submitReason() {
        if (gContext.confirmModal.reason != "") {
            setError("");
            SetDisabled(true);
            gContext.setconfirmModal({ ...gContext.confirmModal, ["confirmed"]: true });
            gContext.toggleConfirmModal();
        }
        else {
            setError("Reason is required");
        }
    }
    function confirmMessage() {
        if (gContext.confirmModal.page ==null && gContext.confirmModal.mode == "feat") {
            let { error, ...rest } = values;
            error.feature = (values.feature) ? "" : "Feature Logo is required";
            error.featureTypeValue = (values.featureTypeValue == "") ? "Feature Type Value is required" : "";
            setValues({ ...values, error });
            if (error.feature !== "" || error.featureTypeValue != "")
                return;
        }

        SetDisabled(true);
        let resumeName;
        if (values.feature && (gContext.confirmModal.mode == "feat" || gContext.confirmModal.mode == "unfeat") ){ 
        //if (values.feature && (gContext.confirmModal.mode == "feat"  )) {
            resumeName = Date.now() + '.' + values.feature.name.split('.').pop() 
            FileUpload(values.feature, resumeName , gContext.confirmModal.sub);
        }

        gContext.setconfirmModal({ ...gContext.confirmModal, ["confirmed"]: true, ["featuredLogo"]: "admin/" + gContext.confirmModal.sub + "/" + resumeName, ["featuredType"]:values.featureTypeValue });
        gContext.toggleConfirmModal();
    }
    const handleChange = (e) => {

        if (e.target.value.toLowerCase() == "delete")
            SetDisabled(false);
        else
            SetDisabled(true);
    }
    useEffect(() => {

        if (gContext.confirmModal.mode && gContext.confirmModal.mode != "delete")
            SetDisabled(false);
        else
            SetDisabled(true);
        setValues({
            resetFeatureName: '', featureName: '', feature: null, featureType: '', featureTypeValue: '', error: { featureTypeValue: "", feature: "" }
        });
    }, [gContext.confirmModal]);
    return (
        <ModalStyled
            {...props}
            size={(gContext.confirmModal.mode == "reject" || gContext.confirmModal.mode == "feat"  )?"lg":"md"}
            centered
            show={gContext.confirmModalVisible}
            onHide={gContext.toggleConfirmModal}
        >
            {(!gContext.confirmModal.userType || (gContext.confirmModal.userType && gContext.confirmModal.userType == "unfeat")) &&

                <Modal.Body className="p-0">
                    <button
                        type="button"
                        className="circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper"
                        onClick={handleClose}
                    >
                        <i className="fas fa-times"></i>
                    </button>
                    {/*    <div className="login-modal-main bg-white rounded-8 overflow-hidden">
                  <div className="media   px-10 py-5 bg-red-opacity-2  ">
                     
                       
                    

                      <div className=" align-items-left  ">
                          <h3 className="mb-0">

                              <span className="font-size-6   text-red">
                                  <i className="fa fa-lg fa-exclamation-circle text-red pr-5"></i>    Error
                                  </span>

                          </h3>

                        
                      </div>
                  </div>
          <div className="row no-gutters">

                      <div className="col-md-6">
                          <div className="media align-items-center px-10 py-10">  
                          
                              
                              <div className=" align-items-left px-5 py-5">
                                  <a className="font-size-4 text-default-color line-height-2">
                                      {gContext.errorModal}
                                </a>
                                  
                              </div>
                          </div>
                      </div>

                     
          </div>
              </div>
              */}


                    <div className="row no-gutters w-100">

                        <div className="col-md-12 col-12 w-100 px-5 py-5">

                            <div className=" align-items-center px-5 py-5">
                                <div className="font-size-4   font-weight-semibold  line-height-2">
                                    {gContext.confirmModal.mode === "reject" ? "" : gContext.confirmModal.text}
                                </div>

                                {gContext.confirmModal.mode === "reject" &&
                                    <>  <div className="font-size-4   font-weight-semibold  line-height-2">
                                        Enter Reason  <span className="error font-size-3">* ( Max 50 Character)</span>
                                        <br /><span className="font-size-3 text-green-2 font-weight-semibold"> Remaining Character length: {50 - ((gContext.confirmModal.reason) ? gContext.confirmModal.reason.length : 0)}</span><div className="font-size-4 pt-5   font-weight-semibold  line-height-2">
                                        </div>
                                        <textarea
                                            name="desc"
                                            id="desc" maxLength="50"
                                            cols="20"
                                            rows="3" defaultValue={gContext.confirmModal.reason} Value={gContext.confirmModal.reason}
                                            className={`border   ${(error.length > 0) ? 'border-red' : 'border-mercury'} text-gray w-100 pt-4 pl-6`}
                                            placeholder="Enter reason" onChange={(e) => gContext.setconfirmModal({ ...gContext.confirmModal, ["reason"]: e.target.value })}
                                        ></textarea>{error.length > 1 && (
                                            <span className="error font-size-3">{error}</span>
                                        )}
                                    </div>
                                        <div className=" mt-5 media pt-2 w-100 mr-0  justify-content-md-end">   <button type="button"
                                            className=" btn-green   font-size-3 font-weight-bold rounded-5 border-0 text-uppercase" onClick={() => {
                                                submitReason();

                                            }}>
                                            Submit
                                          </button><button onClick={handleClose} type="button" className="btn-green  border-0  font-size-3 font-weight-bold ml-2   rounded-5 text-uppercase"  >
                                                Cancel
                                          </button></div></>
                                }

                                {gContext.confirmModal.mode !== "reject" && <>
                                    {(!gContext.confirmModal.mode && gContext.confirmModal.mode != 'post') &&
                                        <div className="font-size-4 pt-5   font-weight-semibold  line-height-2">
                                            <input
                                                name="txtDelete"
                                                type="text"
                                                className="form-control h-px-40"
                                                id="txtDelete"
                                                placeholder={"Enter 'delete'"} onChange={handleChange}
                                            />
                                        </div>}
                                    <div className=" mt-5 media pt-2 w-100 mr-0  justify-content-md-end">   <button type="button" disabled={disabled}
                                        className=" btn-green   font-size-3 font-weight-bold rounded-5 border-0 text-uppercase" onClick={() => {
                                              confirmMessage();

                                        }}>
                                        Yes
                                          </button><button onClick={handleClose} type="button" className="btn-green  border-0  font-size-3 font-weight-bold ml-2   rounded-5 text-uppercase"  >
                                            No
                                          </button></div>
                                </>} </div>

                        </div>


                    </div>

                </Modal.Body>}

            {((gContext.confirmModal.userType && gContext.confirmModal.userType == "feat") ) &&

                <Modal.Body className="p-0">
                    <button
                        type="button"
                        className="circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper"
                        onClick={handleClose}
                    >
                        <i className="fas fa-times"></i>
                    </button>
                 

                    <div className="row no-gutters w-100">

                        <div className="col-md-12 col-12 w-100 ">

                           
                            <div className="font-size-6 font-weight-semibold mb-11    bg-gray-opacity-3 pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">
                               Feature Client
                  </div>


                            <form action="/" className="w-100 pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">
                                <fieldset>

                                    <div className="row w-100 mb-xl-1 mb-9">

                                        
                                        <div className="col-md-12 mb-8">
                                            <div className="form-group">

                                                <label
                                                    htmlFor="labelupload"
                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                >
                                                    <div className="media mb-0 pb-0">  Upload Logo <span className="error font-size-3">* </span>
                                                                        <div className="position-relative w-50"  >
                                                            <div className="circle-20 bg-green-2 ml-3 font-family-Cascadia Code  pointer text-white font-size-4  "
                                                                onMouseOver={() => setUploadTooltip(!uploadtooltip)}
                                                                onMouseLeave={() => setUploadTooltip(!uploadtooltip)}>
                                                                <i className="fa fa-info  font-size-2 "></i>  </div>
                                                            {uploadtooltip === true && <div className="focus-reset mt-10   w-100 pos-abs-tl   z-index-supper bg-white rounded">
                                                                <div className=" border w-100  px-5 font-size-3  z-index-supper bg-gray-opacity-1 rounded shadow-12">
                                                                    <p className="font-size-3 pt-1 font-weight-normal  text-black line-height-1p4"  >
                                                                    <strong className="line-height-1p6">Information</strong> <br />Upload only  .gif,.png,.jpeg extensions and transparent  file. <br /> </p> </div>

                                                            </div>}</div></div>

                                                </label>
                                                {(values.featureName === "") && <div className="upload-file mb-8 text-left " style={{ cursor: 'pointer' }}>



                                                    <label
                                                        htmlFor="fileUpload"
                                                        className="mb-0 font-size-3"
                                                        style={{ cursor: 'pointer' }}>
                                                        {(!values.feature) && <div className=" m-0 p-0   text-center  "  >
                                                            <i className="fa-solid   fa-file-arrow-up font-size-12 pointer text-green " data-toggle="tooltip" title="Click here to add file"></i>
                                                        </div>}

                                                    </label>

                                                    {(values.feature) && <div className=" m-0 p-0 position-relative circle-100 bg-green-opacity-2 border border-width-1 text-center  image-container"  >
                                                        <i className="fa-solid image fa-file-arrow-up font-size-9 pointer text-green-2 " ></i>
                                                        <div className="text-center border border-width-1 border-red circle-30 remove pos-abs-br bg-white "  >
                                                            <i className="fas fa-trash   pointer font-size-4 text-red" data-toggle="tooltip"
                                                                title="Remove resume" onClick={() => setValues({ ...values, ['feature']: null, ['featureName']: values.resetFeatureName })}></i></div></div>}

                                                {(values.feature) && <span className=" pt-5 font-size-4 font-weight-semibold text-green-2"> {(values.feature) ? values.feature.name : ''} </span>}


                                                {values.error.feature.length > 1 && (
                                                    <> <br /> <span className="error font-size-3">{values.error.feature}</span></>
                                                )}

                                                    <input
                                                        type="file"
                                                        id="fileUpload" accept="image/*"
                                                        className="sr-only"
                                                        onChange={onFileChange}
                                                    />

                                                </div>}
                                                {(values.featureName !== "") &&
                                                    <> <div className=" m-0 p-0 position-relative circle-100 bg-green-opacity-2 border border-width-1 text-center  image-container"  ><a href={s3URL + values.featureName} target="blank" data-toggle="tooltip" title="Click here to view."> <i className="fa-solid image fa-file-arrow-up font-size-9 pointer text-green-2 " ></i></a>  <div className="text-center border border-width-1 border-red circle-30 remove pos-abs-br bg-white "  > <i className="fas fa-trash   pointer font-size-4 text-red" data-toggle="tooltip" title="Remove resume" onClick={() => setValues({ ...values, ['feature']: null, ['featureName']: '' })}></i></div></div></>}
                                            </div>

                                        </div>
                                    <div className="col-lg-6 ">
                                        <div className="form-group">
                                            <label
                                                htmlFor="select2"
                                                className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                            >
                                                Feature Link <span className="error font-size-3">* </span>
                                            </label>
                                            <Select name="expLevel" value={values.featureType}
                                                options={[{ value: "W", label: "Website" }, { value: "P", label: "Profile" }]} placeholder="Select..."
                                                className={`form-control pl-0 arrow-3 w-100 font-size-4 d-flex align-items-center w-100   ${(values.error.featureTypeValue.length > 0) ? 'form-control-error' : ''}`}
                                                border={false} onChange={(e) => { let error = { ...values.error }; error.featureTypeValue = ""; setValues({ ...values, error, ['featureType']: e, ['featureTypeValue']: e.value }); }}
                                            />
                                           {values.error.featureTypeValue.length > 1 && (
                                                <> <br /> <span className="error font-size-3">{values.error.featureTypeValue}</span></>
                                            )}
                                        </div>
                                    </div>
                                        <div className=" mt-5 media pt-2 w-100 mr-0  justify-content-md-end">   <button type="button" disabled={disabled}
                                            className="btn  btn-green btn-small  font-size-3 font-weight-bold rounded-5 border-0 text-uppercase" onClick={() => {
                                                confirmMessage();

                                            }}>
                                        Yes
                                          </button><button onClick={handleClose} type="button" className="btn btn-green btn-small  border-0  font-size-3 font-weight-bold ml-2   rounded-5 text-uppercase"  >
                                                No
                                          </button></div></div> </fieldset></form>
                               

                        </div>


                    </div>

                </Modal.Body>}
        </ModalStyled>
    );
};

export default ModalConfirm;
