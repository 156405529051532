import {
    login, registerConfirm, registerCodeResend, refreshToken, logout, timeZone, getExpertProfile, GetProjectLength, proposalCreate, searchExpert
  ,loginMfaResend,createAlert, loginMfaConfirm ,adminJobStatusUpdate, adminCompanyStats,adminfeatureStatusUpdate ,flagUser, adminStatusUpdate,adminUsers,proposalStatusUpdate,searchJobs, getExpertEducation, getExpertExperience, updateExpertPersonal, proposalLoadAll, updateExpertEducation, loadClientProfile, loadClientBusinessProfile, updateExpertExperience, getExpertProfessional, updateExpertProfessional, getExpertSocial, updateExpertSocial, getExpertCertificate, getExpertTestimonial, updateExpertTestimonial, updateExpertCertificate, ClientPreviewLoad, ExpertPreviewLoad, ExpertRowDelete, ClientJobsUpdate, ClientJobsLoad, ClientJobsDelete, clientJobsRead, clientJobsStatusUpdate, clientDashboardCounts,updateReportStatus,adminExpCertStatus
} from './../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import AuthProvider from './../context/AuthProvider'; 

const user = (localStorage.getItem("connect")) ? JSON.parse(localStorage.getItem("connect")) : null;
export async function signInCall(email, password) {

    let data = login.replace("$email", email).replace("$pwd", password);
    let response;
    await API.graphql(graphqlOperation(data)).then((result) => {
        response = result;
    }).catch((error) => { response = error; });

    return response;

}

export async function getRefreshToken(email, token, userType) {

    let data = refreshToken.replace("$email", email).replace("$token", token).replace("$userType", userType);
    let response;
    await API.graphql(graphqlOperation(data)).then((result) => {

        response = result;
    }).catch((error) => { response = error; });

    return response;

}
export async function logoutUser() {
    AuthProvider();
    if (localStorage.getItem("connect")) {

    

        if (user) {
            let data = logout.replace("$email", user.payload["email"]).replace("$token", user.accessToken);
            let response;
            await API.graphql(graphqlOperation(data)).then((result) => {
                response = result;
            }).catch((error) => {
                response = error;
            });
            return response;
        }
    }
}


export async function confirmCode(email, code) {

    let data = registerConfirm.replace("$email", email).replace("$code", code);
    let response;
    await API.graphql(graphqlOperation(data)).then((result) => {
        response = result;
    });

    return response;

}
export async function codeResend(email) {

    let data = registerCodeResend.replace("$email", email);
    let response;
    await API.graphql(graphqlOperation(data)).then((result) => {
        response = result;
    });

    return response;

}
export async function GetTimeZone(code) {

    let data = timeZone.replace("$code", code);
    let response;
    await API.graphql(graphqlOperation(data)).then((result) => {
        response = result;
    });

    return response;

}
export async function GetExpertProfile() {
    let response;
    if (user) {
       
        let data = getExpertProfile.replace("$sub", user.payload["sub"]);

        await API.graphql(graphqlOperation(data)).then((result) => {
            response = result;
        });
    }
    return response;
}
export async function GetExpertProfessional() {
    let response;
    if (user) {
      
        let data = getExpertProfessional.replace("$sub", user.payload["sub"]);

        await API.graphql(graphqlOperation(data)).then((result) => {
            response = result;
        });
        
    }
    return response;

}
export async function GetExpertEducation(expertId) {



    let response;
    if (user) {

        let data = getExpertEducation.replace("$expertId", expertId);;

        await API.graphql(graphqlOperation(data)).then((result) => {

            response = result;
        }).catch((result) => { console.log(result); });
    }
    return response;

}
export async function GetExpertExperience(expertId) {
    let response;
    if (user) {

        let data = getExpertExperience.replace("$expertId", expertId);;

        await API.graphql(graphqlOperation(data)).then((result) => {
            response = result;
        });
    }
    return response;

}

export async function UpdateExpertProfile(profileData) {

    let response;

    if (user) {

        const percentage = 35 + ((profileData.phone != '') ? 1 : 0) + ((profileData.languages != '') ? 1 : 0) + ((profileData.address2.trim() != '') ? 1 : 0);
       
        const data = updateExpertPersonal.replace("$sub", user.payload["sub"]).
            replace("$email", user.payload["email"]).
            replace("$userType", user.payload["custom:usertype"]).
            replace("$fName", profileData.fName.replaceAll('"', ``)).
            replace("$lName", profileData.lName.replaceAll('"', ``)).
            replace("$address1", profileData.address1.replaceAll('"', `'`)).
            replace("$address2", profileData.address2.replaceAll('"', `'`)).
            replace("$avatar", profileData.avatarName).
            replace("$browserCountry", profileData.browserCountry).
            replace("$browserTimezone", profileData.browserTimezone).
            replace("$city", profileData.city.replaceAll('"', `'`)).
            replace("$state", profileData.state.replaceAll('"', `'`)).
            replace("$country", profileData.countryid).
            replace("$phone", profileData.phone).
            replace("$photo", profileData.photoName).
            replace("$timeZone", profileData.timezoneid).
            replace("$percentage", percentage).
            replace("$otherLangs", profileData.languages.replaceAll('"', `'`));

      

        await API.graphql(graphqlOperation(data)).then((result) => {
            response = result;
        }).catch((error) => {
            response = error;
        });
    }
    return response;

}

export async function UpdateExpertProfessional(profileData) {

    let response; // indTypeIds : "$indTypeIds"

    if (user) {

        const percentage = 30 + ((profileData.resumeName != '') ? 5 : 0);

        const data = updateExpertProfessional.replace("$sub", user.payload["sub"]).
            replace("$email", user.payload["email"]).
            replace("$userType", user.payload["custom:usertype"]).
            replace("$title", profileData.title.replaceAll('"', `'`)).
            replace("$skillIds", profileData.skillSet).
            replace("$weeklyHrsId", profileData.weeklyHrsId).
            replace("$resume", profileData.resumeName).
            replace("$profile", profileData.profileDetails.replaceAll('"', `'`)).
            replace("$expLevelId", profileData.expLevelId).
            replace("$dailyRate", profileData.dailyRate).
            replace("$compIds", profileData.compliance).
            replace("$indTypeIds", profileData.industryType).
            replace("$compCatIds", profileData.compCategory).
            replace("$percentage", percentage);

        
        await API.graphql(graphqlOperation(data)).then((result) => {
            response = result;
        }).catch((error) => {
            response = error;
        });
    }
    return response;

}

export async function GetClientProfile() {
    return null;
    let response;
    if (user) {
      
        let client_data = loadClientProfile.replace("$sub", user.payload["sub"]);

        await API.graphql(graphqlOperation(client_data)).then((result) => {
            response = result;

        }).catch(data => { response = data; });

    }
    return response;

}

export async function LoginMfaConfirm(sub, code) {

    let data = loginMfaConfirm.replace("$sub", sub).replace("$code", code);

    let response;
    await API.graphql(graphqlOperation(data)).then((result) => {
        response = result;
    }).catch((error) => { response = error; });

    return response;

}

export async function UpdateClientProfile(profileData) {
    return null;
    let response;

    const mutation = `mutation CPWMutation {
        clientPerUpdate(input: {email: "$email", phone: "$phone", sub: "$sub", firstName: "$fName", lastName: "$lName", profilePercentage: $percentage}) {
          responseData {
            code
            errors
            message
            status
          }
        }
      }`;


    if (user) {

        // const percentage = 20+20 ;
        const percentage = 40 + ((profileData.phoneNumber != '') ? 5 : 0);

       
        const data = mutation.replace("$sub", user.payload["sub"]).
            replace("$email", user.payload["email"]).
            replace("$fName", profileData.fName.replaceAll('"', ``)).
            replace("$lName", profileData.lName.replaceAll('"', ``)).
            replace("$phone", profileData.phoneNumber).
            replace("$percentage", percentage);

        await API.graphql(graphqlOperation(data)).then((result) => {
            response = result;
        }).catch((error) => {
            response = error;
        });
    }
    return response;
}

export async function GetClientBusinessProfile() {
    let response;
    if (user) {
       
        let data = loadClientBusinessProfile.replace("$sub", user.payload["sub"]);
        console.log(data);
        await API.graphql(graphqlOperation(data)).then((result) => {
            console.log(result);   response = result;
        });
    }
 
    return response;

}

export async function UpdateClientBusinessProfile(profileData) {
    let response;
    const mutation = `mutation CBUMutation {
                    clientBusUpdate(input:{sub: "$sub", email: "$email", address1: "$address1", address2: "$address2", certificate: "$certificate", city: "$city", company: "$company", countryId: $countryId, desc: "$desc", indTypeIds : "$indTypeIds" , logo: "$logo", profilePercentage: $percentage, state: "$state", tagline: "$tagline", timezoneId: $timeZone, website: "$website"})
                    {
                    responseData {
                    code
                    errors
                    message
                    status
                  }
                }
              }`;


    if (user) {

        const percentage = 50 + ((profileData.tagline != '') ? 3 : 0) + ((profileData.address2 != '') ? 2 : 0);
        const data = mutation.replace("$sub", user.payload["sub"]).
            replace("$email", user.payload["email"]).
            replace("$address1", profileData.address1.replaceAll('"', `'`)).
            replace("$address2", profileData.address2.replaceAll('"', `'`)).           
            replace("$certificate", profileData.certificatename).
            replace("$city", profileData.city.replaceAll('"', `'`)).
            replace("$company", profileData.cname.replaceAll('"', `'`)).
            replace("$countryId", profileData.countryid).
            replace("$desc", profileData.cdesc.replaceAll('"', `'`)).
            replace("$indTypeIds", profileData.industryType).
            replace("$logo", profileData.clogoname).
            replace("$percentage", percentage).
            replace("$state", profileData.state.replaceAll('"', `'`)).
            replace("$tagline", profileData.tagline.replaceAll('"', `'`)).
            replace("$timeZone", profileData.timezoneid).
            replace("$website", profileData.cwebsite);
       

        await API.graphql(graphqlOperation(data)).then((result) => {
            response = result;
        }).catch((error) => {
            response = error; console.log(response);
        });

        return response;
    }

}

export async function UpdateExpertEducation(row, count) {

    let response;

    if (user) {
        //const percentage = (eduData.filter(item => item["id"] == null).length == 0) ?5:0;
        const percentage = 5;
        //eduData.map(async (row) => {
        //    console.log(row); [$ID]
        const data = updateExpertEducation.replace("$sub", user.payload["sub"]).
            replace("[$ID]", (row.id) ? "id:" + row.id.toString() + ',' : '').
            replace("$email", user.payload["email"]).
            replace("$expertId", row.expertId).
            replace("$degreeId", (row.degree) ? row.degree.value : null).
            replace("$startDate", row.fromDate.toISOString().slice(0, 10)).
            replace("$endDate", row.toDate.toISOString().slice(0, 10)).
            replace("$major", row.major.replaceAll('"', `'`)).
            replace("$countryId", (row.country) ? row.country.value : null).
            replace("$profilePercentage", percentage).
            replace("$school", row.school.replaceAll('"', `'`));

       
        await API.graphql(graphqlOperation(data)).then((result) => {

            response = result;
        }).catch((error) => {
           
            response = error;
        });
        //});

    }
  
    return response;

}

export async function UpdateExpertExperience(row, count) {

    let response;
    
    if (user) {
         
        const percentage = 5;
       
        const data = updateExpertExperience.replace("$sub", user.payload["sub"]).
            replace("[$ID]", (row.id) ? "id:" + row.id.toString() + ',' : '').
            replace("$email", user.payload["email"]).
            replace("$expertId", row.expertId).
            replace("$company", row.company.replaceAll('"', `'`)).
            replace("$startDate", row.fromDate.toISOString().slice(0, 10)).
            replace("[$endDate]", (row.toDate) ? "endDate:\"" + row.toDate.toISOString().slice(0, 10) + "\"," : '').
            replace("$current", row.current).
            replace("$expYears", 2).
            replace("$profilePercentage", percentage).
            replace("$role", row.role.replaceAll('"', `'`));
      
        await API.graphql(graphqlOperation(data)).then((result) => {
            response = result;
        }).catch((error) => {
            response = error;
        });
    }
   
    return response;

}
export async function GetExpertSocial(expertId) {
    let response;
    if (user) {
        let data = getExpertSocial.replace("$expertId", expertId);
        await API.graphql(graphqlOperation(data)).then((result) => {
            response = result;
        });
    }
  
    return response;

}
export async function GetExpertCertificate(expertId) {
    let response;
    if (user) {
        let data = getExpertCertificate.replace("$expertId", expertId);
        await API.graphql(graphqlOperation(data)).then((result) => {
            response = result;
        });
    }
    
    return response;

}
export async function GetExpertTestimonial(expertId) {
    let response;
    if (user) {
        let data = getExpertTestimonial.replace("$expertId", expertId);
        await API.graphql(graphqlOperation(data)).then((result) => {
            response = result;
        });
    }
   
    return response;

}
export async function UpdateExpertSocial(row, count) {

    let response;

    if (user) {
        //const percentage = (eduData.filter(item => item["id"] == null).length == 0) ?5:0;
        const percentage = 2;
        //eduData.map(async (row) => {
        //    console.log(row);
        // {email: "$email", expertId: $expertId, linkId: $linkId, profilePercentage: $profilePercentage, sub: "$sub", url: "$url"}) {
        //  id:null,company: '', role: '', experience: '',current:true, fromDate: '', toDate: ''
        const data = updateExpertSocial.replace("$sub", user.payload["sub"]).
            replace("[$ID]", (row.id) ? "id:" + row.id.toString() + ',' : '').
            replace("$email", user.payload["email"]).
            replace("$expertId", row.expertId).
            replace("$linkId", row.provider.value).
            replace("$profilePercentage", percentage).
            replace("$url", row.link);

       

        await API.graphql(graphqlOperation(data)).then((result) => {
            response = result;
        }).catch((error) => {
            response = error;
        });
    }
    
    return response;

}

export async function UpdateExpertCertificate(row) {

    let response;

    if (user) {
        //const percentage = (eduData.filter(item => item["id"] == null).length == 0) ?5:0;
        const percentage = 10;
        //eduData.map(async (row) => {
        //    console.log(row); [$ID]
        const data = updateExpertCertificate.replace("$sub", user.payload["sub"]).
            replace("[$ID]", (row.id) ? "id:" + row.id.toString() + ',' : '').
            replace("$email", user.payload["email"]).
            replace("$expertId", row.expertId).
            replace("$name", row.name.replaceAll('"', `'`)).
            replace("$fileName", row.fileName).
            replace("$profilePercentage", percentage).
            replace("$url", row.digitalLink);

     

        await API.graphql(graphqlOperation(data)).then((result) => {

            response = result;
        }).catch((error) => {
           
            response = error;
        });
       

    }
    
    return response;

}
export async function UpdateExpertTestimonial(row) {

    let response;

    if (user) {
        //const percentage = (eduData.filter(item => item["id"] == null).length == 0) ?5:0;
        const percentage = 5;
        //eduData.map(async (row) => {
        //    console.log(row); [$ID]
        const data = updateExpertTestimonial.replace("$sub", user.payload["sub"]).
            replace("[$ID]", (row.id) ? "id:" + row.id.toString() + ',' : '').
            replace("$email", user.payload["email"]).
            replace("$expertId", row.expertId).
            replace("$name", row.name.replaceAll('"', `'`)).
            replace("$fileName", row.fileName).
            replace("$profilePercentage", percentage).
            replace("$url", row.digitalLink);
 

        await API.graphql(graphqlOperation(data)).then((result) => {

            response = result;
        }).catch((error) => {
           
            response = error;
        });
        //});

    }
    
    return response;

}
export async function GetClientPreviewProfile(sub) {
    let response;
    return null;
    let data = ClientPreviewLoad.replace("$sub", (sub) ? sub : user.payload["sub"]);
    await API.graphql(graphqlOperation(data)).then((result) => {
        response = result;
    });

    return response;

}
export async function GetExpertPreviewLoad(sub) {
    let response;

    let data = ExpertPreviewLoad.replace("$sub", (sub) ? sub : user.payload["sub"]);
    await API.graphql(graphqlOperation(data)).then((result) => {
        response = result;
        console.log('response',response)
    });

    
    return response;

}
export async function DeleteExpertRow(id, type) {
    let response;
    let data = ExpertRowDelete.replace("$id", id).replace("$type", type);
    await API.graphql(graphqlOperation(data)).then((result) => {
        response = result;
    });

    
    return response;
}

export async function UpdateClientJob(jobData) {

    let response;

    if (user) {
        const data = ClientJobsUpdate.replace("$email", user.payload["email"]).
            replace("[$ID]", (jobData.id) ? "id:" + jobData.id.toString() + ',' : '').
            replace("$title", jobData.title.replaceAll('"', `'`)).
            replace("$skillIds", jobData.skillSet).
            replace("$weeklyHrsId", jobData.weeklyHrsId).
            replace("$desc", jobData.desc).
            replace("$expLevelId", jobData.expLevelId).
            replace("$projLengthId", jobData.projLengthId).
            replace("$indTypeIds", jobData.industryType).            
            replace("$dailyRate", jobData.dailyRate).
            replace("$hourlyRate", jobData.hourlyRate).
            replace("$fixedRate", jobData.fixedRate).
            replace("$countryId", jobData.countryId).
            replace("$compCatIds", jobData.compCategory).
            replace("$rateFrom", jobData.rateRangeFrom).
            replace("$rateTo", jobData.rateRangeTo).
            replace("$type", jobData.jobType).
            replace("$city", jobData.city.replaceAll('"', `'`)).
            replace("$state", jobData.state.replaceAll('"', `'`)).
            replace("$clientId", jobData.clientId).
            replace("$statusId", (jobData.statusId) ? jobData.statusId : '').
            replace("$compIds", jobData.compliance);


        console.log(data);

        await API.graphql(graphqlOperation(data)).then((result) => {
            response = result;
        }).catch((error) => {
            response = error;
        });
    }
    return response;

}
export async function DeleteClientJob(id, clientId) {
    let response;
    let data = ClientJobsDelete.replace("$id", id).replace("$clientId", clientId);
    await API.graphql(graphqlOperation(data)).then((result) => {
        response = result;
    });
   
    return response;
}

export async function GetClientJobs(clientId) {
    let response;
    if (clientId) {

        let data = ClientJobsLoad.replace("$clientId", clientId);
        await API.graphql(graphqlOperation(data)).then((result) => {
            response = result;
        });
    }
    return response;
}

export async function ClientJobsRead(guid) {
    let response;
    let data = clientJobsRead.replace("$guid", guid);
    
    await API.graphql(graphqlOperation(data)).then((result) => {
        response = result;
    });
   
    return response;
}
export async function ClientJobsStatusUpdate(id, clientId, code) {
    let response;
    let data = clientJobsStatusUpdate.replace("$id", id).replace("$clientId", clientId).replace("$jobCode", code);
    await API.graphql(graphqlOperation(data)).then((result) => {
        response = result;
    });
  
    return response;
}
export async function ClientDashboardCounts(clientId) {
    let response;
    let data = clientDashboardCounts.replace("$clientId", clientId);
    await API.graphql(graphqlOperation(data)).then((result) => {
        response = result;
    });
   
    return response;
}
export async function SearchJobs(keyWords) {
    let response;   
    let data = searchJobs.replace("$keyword", keyWords.keyword).replace("$country", keyWords.country).
        replace("$expLvlIds", (keyWords.expLvlIds) ? keyWords.expLvlIds : '').replace("$jobTypes", (keyWords.jobTypes) ? keyWords.jobTypes : '').
        replace("$projLengthIds", (keyWords.projLengthIds) ? keyWords.projLengthIds : '')
        .replace("$weeklyHrsIds", (keyWords.weeklyHrsIds) ? keyWords.weeklyHrsIds : '')
        .replace("$skillIds", (keyWords.skillIds) ? keyWords.skillIds : '')
        .replace("$complianceCategoryIds", (keyWords.complianceCategoryIds) ? keyWords.complianceCategoryIds : '')
        .replace("$complianceStandardIds", (keyWords.complianceStandardIds) ? keyWords.complianceStandardIds : '')
        .replace("$industryIds", (keyWords.industryIds) ? keyWords.industryIds : '')
        .replace("$dateSince", (keyWords.dateSince) ? keyWords.dateSince : '')
        .replace("$rateRangeFrom", (keyWords.rateRangeFrom) ? keyWords.rateRangeFrom : '')
        .replace("$rateType", (keyWords.rateType) ? keyWords.rateType : '')       
        .replace("$rateRangeTo", (keyWords.rateRangeTo) ? keyWords.rateRangeTo : '');
    console.log(data);
    await API.graphql(graphqlOperation(data)).then((result) => {
        response = result;
        console.log(response);
    });
    return response;
}
export async function Createproposal(proposalData) {
    let response;
    if (user) {
      
        const data = proposalCreate.replace("$attachments", proposalData.resumeName).
            replace("$bid", proposalData.bidRate).
            replace("$email", proposalData.email).
            replace("$clientId", proposalData.clientId).
            replace("$coverLetter", proposalData.proposalSummary).
            replace("$expertId", proposalData.expertId).
            replace("$jobId", proposalData.jobId);
        
        await API.graphql(graphqlOperation(data)).then((result) => {
            response = result;
        }).catch((error) => {
              response = error;
        });
    }
    return response;

}

export async function ProposalLoadAll(clientId) {
    let response;
    if (user) {
        const data = proposalLoadAll.replace("$clientId", clientId);
        await API.graphql(graphqlOperation(data)).then((result) => {
            response = result;
        }).catch((error) => {
             response = error;
        });
    }
    return response;
}

export async function SearchExpert(keyWords) {
    let response;
    let data = searchExpert.replace("$keyword", keyWords.keyword).replace("$country", keyWords.country)
        .replace("$expLvlIds", (keyWords.expLvlIds) ? keyWords.expLvlIds : '')        
        .replace("$weeklyHrsIds", (keyWords.weeklyHrsIds) ? keyWords.weeklyHrsIds : '')
        .replace("$avgRating", (keyWords.avgRating) ? keyWords.avgRating : '0')
        .replace("$otherLangs", (keyWords.otherLangs) ? keyWords.otherLangs : '')
        .replace("$skillIds", (keyWords.skillIds) ? keyWords.skillIds : '')
        .replace("$complianceCategoryIds", (keyWords.complianceCategoryIds) ? keyWords.complianceCategoryIds : '')
        .replace("$complianceStandardIds", (keyWords.complianceStandardIds) ? keyWords.complianceStandardIds : '')
        .replace("$industryIds", (keyWords.industryIds) ? keyWords.industryIds : '')
        .replace("$dateSince", (keyWords.dateSince) ? keyWords.dateSince : '')
        .replace("$rateRangeFrom", (keyWords.rateRangeFrom) ? keyWords.rateRangeFrom : '')       
        .replace("$rateRangeTo", (keyWords.rateRangeTo) ? keyWords.rateRangeTo : '');
    console.log(data);
    await API.graphql(graphqlOperation(data)).then((result) => {
        response = result;
        console.log(response);
    });
    return response;
}
export async function ProposalStatusUpdate(id, code) {
    let response;
    let data = proposalStatusUpdate.replace("$id", id).replace("$statusCode", code);
    await API.graphql(graphqlOperation(data)).then((result) => {
        response = result;
    }); 
    return response;
}
export async function AdminUsers(fetchType, userType) {
    let response;
    let data = adminUsers.replace("$fetchType", fetchType).replace("$userType", userType);
console.log(data);
    await API.graphql(graphqlOperation(data)).then((result) => {
        response = result;
    });
    return response;
}



export async function AdminfeatureStatusUpdate(userId, userType, status, statusType,  featuredLogo, featuredLinkType) {
     
    let response;
    let data = adminfeatureStatusUpdate.replace("$status", status).replace("$statusType", statusType).replace("$featuredLogo", featuredLogo).replace("$featuredLinkType", featuredLinkType).replace("$userId", userId).replace("$userType", userType);
    console.log(data);
    await API.graphql(graphqlOperation(data)).then((result) => {
        response = result;
    });
    return response;
}

export async function AdminStatusUpdate(status, statusType, userId, userType, reason) {
     
    let response;
    let data = adminStatusUpdate.replace("$status", status).replace("$statusType", statusType).replace("$userId", userId).replace("$userType", userType).replace("$reason", (reason !== "" && status ==="reject") ?   ",rejectReason:\"" + reason+"\"":"");
    console.log(data);
    await API.graphql(graphqlOperation(data)).then((result) => {
        console.log("result",result);
        response = result;
    });
    return response;
}

export async function FlagUser(flagId, userId, userType) {
    let response;
    let data = flagUser.replace("$flagId", flagId).replace("$userId", userId).replace("$userType", userType);
    console.log(data);
    await API.graphql(graphqlOperation(data)).then((result) => {
        response = result;
    });
    return response;
}

export async function UpdateReportStatus(reportId,status) {
    let response;
    //let data = adminStatusUpdate.replace("$status", status).replace("$statusType", statusType).replace("$userId", userId).replace("$userType", userType).replace("$rejectReason", (rejectReason !== "" && status ==="reject") ?   ",rejectReason:\"" + rejectReason+"\"":"");
    let data = updateReportStatus.replace("$reportId",reportId).replace("$status", status);
    
    console.log(data);
    await API.graphql(graphqlOperation(data)).then((result) => {
        response = result;
    }).catch((error) => {
        response = error; ;
   });
    
    return response;
}


export async function AdminJobStatusUpdate(status, id) {

    let response;
    let data = adminJobStatusUpdate.replace("$featured", status).replace("$id", id);
    console.log(data);
    await API.graphql(graphqlOperation(data)).then((result) => {
        response = result;
    });
    return response;
}  

export async function AdminCompanyStats(website) {

    let response;
    let data = adminCompanyStats.replace("$website", website);
    console.log(data);
    await API.graphql(graphqlOperation(data)).then((result) => {
        response = result;
    });
    return response;
}
export async function  AdminExpCertStatus(certId,expertId,status) {

    let response; 
    let data = adminExpCertStatus.replace("$certId", certId).replace("$expertId", expertId).replace("$status", status);
    console.log(data);
    await API.graphql(graphqlOperation(data)).then((result) => {
        response = result;
        console.log(response);
    });
    return response;
}

export async function CreateAlert(alert) {
    let response;
    //input: { alert: "$alert", alertType: "$system", display: $display,status: "$status", toTypeId: $toTypeId, toUserId: $toUserId }) {
    let data = createAlert.replace("[ID]", alert.id ? "id:" + alert.id + "," : "").
        replace("$alert", alert.alert).
        replace("$alertType", alert.alertType).
        replace("$display", alert.display).
        replace("$status", alert.status).
        replace("$toTypeId", alert.toTypeId).
        replace("$toUserId", alert.toUserId);
    await API.graphql(graphqlOperation(data)).then((result) => {
        response = result;
        console.log(response);
    });
    return response;
}

export async function LoginMfaResend(email) {

    let data = loginMfaResend.replace("$email", email);
    let response;
    await API.graphql(graphqlOperation(data)).then((result) => {
        response = result;
    });

    return response;

}